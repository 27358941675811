@import '../../global/styles/lib';

.main-menu {
	$font-color: $body-font-color;

	#menu-main-menu {
		> .menu-item {
			> a {
				text-transform: uppercase;
			} // > a
			&.is-active > a,
			&:hover > a,
			& > a:hover,
			& > a:focus {
				background: $primary-color;
				color: $white;
			}
		} // > .menu-item
	} // #menu-main-menu
	.menu-item {
		> a {
			color: rgba($font-color, .55);
			font-size: 95%;
			font-weight: 700;
			letter-spacing: rem-calc(2);
			transition: color $hover-transition, background $hover-transition;
		} // > a
		&:hover > a,
		& > a:hover,
		& > a:focus {
			color: $primary-color;
		}
		&.current-menu-item {
			> a, > a:hover, > a:focus {
				cursor: default;
				color: $font-color;
				background: inherit;
			}
		} // &.current-menu-item
	} // .menu-item
	.dropdown.menu > li.is-dropdown-submenu-parent {
		> .is-dropdown-submenu {
			right: auto;
			left: 50%;
			transform: translateX(-50%);
			padding: 2rem 1rem;

			> .is-dropdown-submenu-item > a {
				white-space: nowrap;
			}
		} // > .is-dropdown-submenu
		> a:after {
			border-top-color: inherit;
			left: unset;
		}
	} // .dropdown.menu > li ...
	.is-dropdown-submenu {
		box-shadow: 0 1rem .75rem -.35rem rgba(#4c494a, .59);
		@supports (filter: drop-shadow(0 0 0 #000)) {
			border: 0;
		}

		.is-dropdown-submenu-parent.opens-left {
			> a:after,
			> a:visited:after {
				border-right-color: inherit;
			}
		}
	} // .is-dropdown-submenu

	button[data-toggle="main-menu-nav"] {
		font-size: .75rem;
		text-transform: uppercase;
		white-space: nowrap;

		> .menu-icon {
			position: relative;
			top: rem-calc(-1);
			color: inherit;
			margin-left: .2rem;
		}
	}

	.menu-extras {
		& > li {
			vertical-align: middle;
		} // & > li
		&.desktop {
			display: none;
			@include breakpoint(large up) {
				display: block;
			} // large up
		} // &.desktop
	}

	.main-hamburger {
		border: none;
		background-color: $transparent;
		color: $black;
		
		#{$hovfoc} {
			background: $transparent !important;
			.menu-icon {
				&::after {
					background: $most-red;
					box-shadow: 0 7px 0 $most-red, 0 14px 0 $most-red;
				} // &::after
			} // .menu-icon
		} // hover-focus

	} // .main-hamburger

} // .main-menu

#main-menu-nav {
	@include breakpoint(medium down) {
		&.off-canvas {
			.menu-item {
				> a {
					color: $white;
					border-bottom: rem-calc(1) solid rgba($white, .25);
				} // > a
				&.is-accordion-submenu-item > a {
					padding-left: nth($menu-item-padding, 2) * 2;
				}
				&.is-accordion-submenu-item .is-accordion-submenu-item > a {
					padding-left: nth($menu-item-padding, 2) * 3;
				}
			} // .menu-item
			.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a:after {
				border-top-color: inherit;
			}
		} // &.off-canvas
	} // medium down
} // #main-menu-nav

.mobile-slide-menu {
	@include absolute-full;
	display: none;
	position: fixed;
	background-color: rgba(black, 0);
	z-index: 20;
	overflow: hidden;
	transition: background-color $header-transition;
	.container {
		position: fixed;
		right: 0;
		width: 60%; // Have to leave some room for people to click away
		height: 100%;
		background-color: $white;
		transform: translateX(100%); // push off screen
		transition: transform $header-transition;
	} // .container

	&.slide-in {
		background-color: rgba(black, 0.75);
		.container {
			transform: translateX(0%);
		} // .container
	} // &.slide-in

	a {
		word-break: break-word;
		white-space: pre-wrap;
	} // a
	
	.menu-item {
		a {
			background: $white;
		} // a
	} // .menu-item

} // .mobile-slide-menu