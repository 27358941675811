/**
 * Imports libraries of settings, mixins, functions, etc. that should be available
 * to all stylesheets. MUST NOT OUTPUT STYLES.
 *
 * @author    <corey@chatmandesign.com>
 * @since     3.0.0
 */
/**
 * Breakpoint range variables for the lock mixin.
 * Convert the breakpoints map into range variables like we had in ZF5. The
 * magic number at the end is the difference between the breakpoint we define in
 * $breakpoints and the actual max-width value spit out by the breakpoints()
 * mixin when we give it an "only" argument (to avoid overlap. See the ZF5 media
 *  query range variables for reference.)
 *
 * $small-range is a special snowflake. The whole purpose of the lock mixin is
 * to let you set a property to a fixed value up to a certain breakpoint, then
 * have that value increase in a linear fashion until you hit another
 * breakpoint, and then keep the value fixed again for the rest of the way. The
 * way the breakpoints are defined, the small range would be 0px - 640px. But
 * we're not planning on supporting devices smaller than 320px, so it's far more
 * useful for the small range to be 320px to 640px. That way, the $min-value you
 * pass to the lock mixin is anchored to the smallest device width we support,
 * and the $max-value is anchored to the breakpoint between small and medium
 * ranges.
 *
 * @author   <dustin@chatmandesign.com>
 */
/**
 * Sass Mixins
 *
 * @package   seedyjoints
 * @since     1.0
 */
/**
 * Extend element's background to left and right edges of viewport.
 *
 * @param string $position  Any valid CSS position value, or NULL/FALSE to not set
 *                          position; but note the element must create a positioning
 *                          context for the bleed effect to work. Default: relative.
 * @param string $direction Direction the bleed will extend in -- left, right, both.
 *                          Deafult: both.
 */
/**
 * Lock (Fluid Type Mixin -- CD Remix)
 * Created 10/11/16 @ 02:19:30 PM
 *
 * Original from here:
 * http://madebymike.com.au/writing/precise-control-responsive-typography/
 *
 * This is a modified version of the above mixin that takes a single SCSS list
 * instead of two variables for min and max VW. This makes it easier to use with
 * the existing Foundation range variables in _settings.scss. You can of course
 * still pass a plain list e.g. "(20rem, 40rem)" as the argument for the range
 * parameter if you need a custom range.
 *
 * @param      $properties A space separated list of CSS properties
 * @param      $vw-range A SCSS list defining a viewport width range like
 *             "(20em, 40em)".
 * @param      $min-value A minimum property value in px/em/rem
 * @param      $max-value A maximum property value in px/em/rem
 * @author     Chatman Design / Dustin Paluch <dustin@chatmandesign.com>
 */
/**
 * List items inline, separated by pipes by default.
 *
 * @since    3.0.0
 * @author   <corey@chatmandesign.com>
 */
/**
 * Transition property with vendor prefixes (DEPRECATED)
 *
 * DEPRECATED now that we use autoprefixer. Just use the standard CSS property.
 *
 * I totally rewrote this to be cleaner and easier to use. You'll need to be using
 * Sass 3.2+ for these to work. Thanks to @anthonyshort for the inspiration on these.
 *
 * USAGE: @include transition(<property> <time> <easing>);
 */
/**
 * CSS3 Gradients with vendor prefixes
 *
 * Be careful with these since they can really slow down your CSS. Don't overdo it.
 */
/* @include css-gradient(#dfdfdf,#f8f8f8); */
/**
 * Foundation for Sites by ZURB
 * Version 6.4.0
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
.main-menu #menu-main-menu > .menu-item > a {
  text-transform: uppercase;
}
.main-menu #menu-main-menu > .menu-item.is-active > a, .main-menu #menu-main-menu > .menu-item:hover > a, .main-menu #menu-main-menu > .menu-item > a:hover, .main-menu #menu-main-menu > .menu-item > a:focus {
  background: #c10230;
  color: #fefefe;
}
.main-menu .menu-item > a {
  color: rgba(69, 69, 69, 0.55);
  font-size: 95%;
  font-weight: 700;
  letter-spacing: 0.125rem;
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
}
.main-menu .menu-item:hover > a, .main-menu .menu-item > a:hover, .main-menu .menu-item > a:focus {
  color: #c10230;
}
.main-menu .menu-item.current-menu-item > a, .main-menu .menu-item.current-menu-item > a:hover, .main-menu .menu-item.current-menu-item > a:focus {
  cursor: default;
  color: #454545;
  background: inherit;
}
.main-menu .dropdown.menu > li.is-dropdown-submenu-parent > .is-dropdown-submenu {
  right: auto;
  left: 50%;
  transform: translateX(-50%);
  padding: 2rem 1rem;
}
.main-menu .dropdown.menu > li.is-dropdown-submenu-parent > .is-dropdown-submenu > .is-dropdown-submenu-item > a {
  white-space: nowrap;
}
.main-menu .dropdown.menu > li.is-dropdown-submenu-parent > a:after {
  border-top-color: inherit;
  left: unset;
}
.main-menu .is-dropdown-submenu {
  box-shadow: 0 1rem 0.75rem -0.35rem rgba(76, 73, 74, 0.59);
}
@supports (filter: drop-shadow(0 0 0 #000)) {
  .main-menu .is-dropdown-submenu {
    border: 0;
  }
}
.main-menu .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a:after,
.main-menu .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a:visited:after {
  border-right-color: inherit;
}
.main-menu button[data-toggle=main-menu-nav] {
  font-size: 0.75rem;
  text-transform: uppercase;
  white-space: nowrap;
}
.main-menu button[data-toggle=main-menu-nav] > .menu-icon {
  position: relative;
  top: -0.0625rem;
  color: inherit;
  margin-left: 0.2rem;
}
.main-menu .menu-extras > li {
  vertical-align: middle;
}
.main-menu .menu-extras.desktop {
  display: none;
}
@media screen and (min-width: 64em) {
  .main-menu .menu-extras.desktop {
    display: block;
  }
}
.main-menu .main-hamburger {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  color: #0a0a0a;
}
.main-menu .main-hamburger:hover, .main-menu .main-hamburger:focus {
  background: rgba(0, 0, 0, 0) !important;
}
.main-menu .main-hamburger:hover .menu-icon::after, .main-menu .main-hamburger:focus .menu-icon::after {
  background: #c10230;
  box-shadow: 0 7px 0 #c10230, 0 14px 0 #c10230;
}

@media screen and (max-width: 63.9375em) {
  #main-menu-nav.off-canvas .menu-item > a {
    color: #fefefe;
    border-bottom: 0.0625rem solid rgba(254, 254, 254, 0.25);
  }
  #main-menu-nav.off-canvas .menu-item.is-accordion-submenu-item > a {
    padding-left: 2rem;
  }
  #main-menu-nav.off-canvas .menu-item.is-accordion-submenu-item .is-accordion-submenu-item > a {
    padding-left: 3rem;
  }
  #main-menu-nav.off-canvas .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a:after {
    border-top-color: inherit;
  }
}

.mobile-slide-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  z-index: 20;
  overflow: hidden;
  transition: background-color 0.5s ease-in-out;
}
.mobile-slide-menu .container {
  position: fixed;
  right: 0;
  width: 60%;
  height: 100%;
  background-color: #fefefe;
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out;
}
.mobile-slide-menu.slide-in {
  background-color: rgba(0, 0, 0, 0.75);
}
.mobile-slide-menu.slide-in .container {
  transform: translateX(0%);
}
.mobile-slide-menu a {
  word-break: break-word;
  white-space: pre-wrap;
}
.mobile-slide-menu .menu-item a {
  background: #fefefe;
}